import React from "react";
import style from "./Homepage.module.css";
import Apple from "../../Assets/Apple.svg";
import GoogleIcon from "../../Assets/playStore.svg";
import Logo from "../../Assets/Logo.svg";
import WordAnimation from "../../pages/ContactCard/AnimationText";
import Footer from "./Footer";
import { logEvent } from "firebase/analytics";
import analytics from "../../firebase";
function Homepage() {
  const handleGooglePlayClick = (platform) => {
    // Log the click event for Google Play button
    logEvent(analytics, `BN ${platform} Download Click`, {
      platform: "Web",
    });
  };

  return (
    <div className={style.appContainer}>
      <header className={style.logo}>
        <img src={Logo} alt="App Logo" />
      </header>
      {/* <hr
        style={{ border: "none", borderTop: "2px solid lightgray" }}
        className={style.Divider}
      ></hr> */}

      <main className={style.mainText}>
        <p className={style.textHeadings}>making</p>
        <p className={style.textHeadings}>birthdays</p>
        <WordAnimation />
      </main>

      <div className={style.section}>
        <div className={style.downloadText}>Download App</div>
        <div className={style.buttons}>
          <a
            href="https://apps.apple.com/us/app/bn-making-birthdays-memorable/id6739053550"
            className={style.appStore}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleGooglePlayClick("Apple Store")}
          >
            <img src={Apple} alt="App Store" />
            <span className={style.download}>
              <p>Download on the</p>
              <p className={style.storeName}>App Store</p>
            </span>
          </a>
          {/* <a
            href="https://play.google.com/store/apps?hl=en_IN"
            className={style.googlePlay}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleGooglePlayClick("Google Store")}
          >
            <img src={GoogleIcon} alt="Google Play" />
            <span className={style.Get}>
              <p style={{ textAlign: "left" }}>Get it on</p>
              <p className={style.storeName}>Google Play</p>
            </span>
          </a> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Homepage;

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBZw8eRJeG74B13z5mVe6Bx0J5FtZ5XR80",

  authDomain: "bn-app-47a27.firebaseapp.com",

  projectId: "bn-app-47a27",

  storageBucket: "bn-app-47a27.firebasestorage.app",

  messagingSenderId: "333318818053",

  appId: "1:333318818053:web:d562fb0cfc44f919430f6c",

  measurementId: "G-92VF8TVYDE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;

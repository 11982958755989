import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/Homepage/Homepage";
import ContactUs from "./pages/ContactUs/ContactUs";
import TermConditions from "./pages/TermConditions/TermConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
// import FirebaseAnalytics from "./components/FirebaseAnalytics";
function App() {
  return (
    <Router>
      {/* <FirebaseAnalytics /> */}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/terms" element={<TermConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;

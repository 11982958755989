import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import style from "./AnimationText.module.css";

const WordAnimation = () => {
  const [wordIndex, setWordIndex] = useState(0);

  const words = ["memorable", "extra special", "fun"];

  // Determine the direction of animation based on word index
  const isBottomToTop = wordIndex % 2 === 0; // Even index = bottom to top, odd index = top to bottom

  const props = useSpring({
    from: {
      transform: isBottomToTop ? "translateY(50px)" : "translateY(50px)", // Bottom-to-top or top-to-bottom starting point
      opacity: 0,
    },
    to: { transform: "translateY(0)", opacity: 1 }, // Final position at center
    reset: true,
    onRest: () => {
      // Once one word animation is finished, move to the next word
      setWordIndex((prev) => (prev+1) % words.length);
    },
    config: { tension: 100, friction: 30 }, // Speed and smoothness of the animation
  });

  return (
    <div className={style.word_animation}>
      <animated.div style={props} className={style.word}>
        <p className={style.textHeadings}>{words[wordIndex]}</p>
      </animated.div>
    </div>
  );
};

export default WordAnimation;

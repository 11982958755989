import React, { useEffect } from "react";
import style from "./PrivacyPolicy.module.css";
import Logo from "../../Assets/Logo.svg";
import { logEvent } from "firebase/analytics";
import analytics from "../../firebase";

function TermConditions() {
  useEffect(() => {
    logEvent(analytics, "BN Privacy Click", {
      platform: "Web",
    });
    document.title = "Privacy Policy - BN";
  }, []);
  return (
    <>
      <div className={style.termsContainer}>
        <header className={style.termsHeader}>
          <a href="/">
            <img src={Logo} alt="Home" />
          </a>
          <h1>Privacy Policy</h1>
        </header>

        <p className={style.termsIntro} style={{ margin: "10px 0px" }}>
          Last Updated : 31 Dec 2024
        </p>
        <p className={style.termsIntro}>
          At BN, your privacy is important to us. This Privacy Policy outlines
          how we collect, use, and protect your personal information when you
          use our app and website (collectively referred to as the “Platform”).
          By using the Platform, you agree to the terms of this Privacy Policy.
        </p>

        <section className={style.termsSection}>
          <p>
            <strong>1.</strong> Information We Collect <br></br>When you use the
            BN Platform, we collect the following types of information:{" "}
            <br></br>
            <br></br>- Personal Information: When you register for an account or
            use certain features, we collect information such as your name,
            phone number and/or email, and birthday. For birthdays added on the
            Platform, we may collect details like name, birthday, label, phone
            number and/or email, and message as required. When adding the
            birthdays of others, you are responsible for ensuring you have
            obtained their consent to share their personal information.
            Additionally, we may collect further information, such as
            preferences (yours or your loved ones') to enhance services like
            personalized gifting or suggestions.<br></br> <br></br>- Usage Data:
            We may collect information about how you interact with the Platform,
            such as your IP address, browser type, operating system, pages you
            visit, and the time you spend on the Platform.<br></br>
            <br></br> - Device Information: We may collect information about the
            device you use to access the Platform, including device type,
            operating system, and version.<br></br>
            <br></br> - Location Data: We may collect location information if
            you grant us permission to access your location for location-based
            services.<br></br>
            <br></br> - Payment Information: If you make purchases for premium
            features or subscriptions, we collect payment details via
            third-party payment processors (such as credit card information,
            billing address, etc).
          </p>
        </section>

        <section className={style.termsSection}>
          <p>
            <strong>2.</strong> How We Use Your Information<br></br> We use the
            information we collect for the following purposes:<br></br>
            <br></br> - To provide and improve the Platform: We use your
            information to operate, personalize, and enhance your experience on
            the Platform.<br></br>
            <br></br> - To process transactions: Your information helps us
            process payments and provide the services you request.<br></br>
            <br></br> - To communicate with you: We may use your contact details
            to send you important updates, promotional offers, reminders, or
            customer support-related messages.<br></br>
            <br></br> - To ensure security: We use your data to monitor for any
            unauthorized access or suspicious activity on the Platform.<br></br>{" "}
            <br></br>- To comply with legal obligations: We may use your
            information to comply with applicable laws and regulations.
          </p>
        </section>

        <section className={style.termsSection}>
          <p>
            <strong>3.</strong> Data Sharing and Disclosure<br></br> We do not
            sell or rent your personal information to third parties. However, we
            may share your information in the following circumstances:<br></br>{" "}
            <br></br>- Service Providers: We may share your information with
            trusted third-party service providers who assist with the operation
            of the Platform, such as payment processors and cloud storage
            providers. These providers are obligated to protect your information
            and use it only for the purpose of providing services to us.
            <br></br>
            <br></br> - Legal Compliance: We may disclose your information if
            required by law, or in response to a valid legal request (such as a
            subpoena, court order, or government investigation).<br></br>
            <br></br> - Business Transfers: In the event of a merger,
            acquisition, or sale of assets, your information may be transferred
            to the new owner of the Platform. We will notify you of any such
            change through a notice on the Platform.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>4.</strong> Data Retention<br></br> We retain your personal
            information for as long as necessary to provide services to you or
            as required by law. You can request account deletion at any time by
            contacting us via email on hello@getbn.in with the subject “Account
            & Data Deletion &lt;your registered email&gt;”. Once you request
            deletion, we will make reasonable efforts to remove your
            information, except where retention is required for legal, security,
            or analytics purposes.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>5.</strong> Security<br></br> We take reasonable measures to
            protect your personal information from unauthorized access, use,
            alteration, or destruction. While we strive to protect your personal
            information, no method of transmission over the internet or
            electronic storage is 100% secure, and we cannot guarantee complete
            security.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>6.</strong> Your Rights<br></br> You have the following
            rights regarding your personal information:<br></br>
            <br></br> - Access: You can request access to the personal data we
            hold about you.<br></br> - Correction: You can update or correct
            your personal information if it is inaccurate.<br></br> - Deletion:
            You can request the deletion of your personal data, subject to
            certain limitations.<br></br> - Opt-out: You can opt out of
            receiving marketing communications from us by following the
            instructions in the communication or by contacting us directly.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>7.</strong> Cookies and Tracking Technologies <br></br>We
            may use cookies and similar technologies to track usage and
            preferences on the Platform. Cookies are small files placed on your
            device to help us improve your experience. You can control cookie
            settings through your browser, but disabling cookies may affect your
            ability to use certain features of the Platform and may limit
            personalized experiences.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>8.</strong> Third-Party Links<br></br> The Platform may
            contain links to third-party websites or services. These third
            parties have their own privacy policies, and we are not responsible
            for their practices. We encourage you to read the privacy policies
            of any third-party sites you visit.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>9.</strong> International Transfers<br></br> Your
            information may be transferred to and maintained on servers located
            outside your country of residence, including locations where data
            protection laws may differ. By using the Platform, you consent to
            the transfer of your information to such locations.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>10.</strong> Changes to This Privacy Policy<br></br> We may
            update this Privacy Policy from time to time. Any changes will be
            posted on the Platform, and the "Last Updated" date at the top of
            this policy will be updated accordingly. By continuing to use the
            Platform after such changes are posted, you agree to the updated
            Privacy Policy.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>11.</strong> Contact Us<br></br> If you have any questions
            or concerns about this Privacy Policy, or if you wish to exercise
            your rights, please contact us at: Email: hello@getbn.in
          </p>
        </section>
        <section style={{ marginTop: "15px" }}>
          <div className={style.copyright}>
            <p>©</p> <p>2024 BN, All rights reserved.</p>
          </div>
        </section>
      </div>
    </>
  );
}

export default TermConditions;

import React, { useEffect } from "react";
import style from "./ContactUs.module.css";
import Logo from "../../Assets/Logo.svg";
import Footer from "../Homepage/Footer";
import analytics from "../../firebase";
import { logEvent } from "firebase/analytics";

function ContactUs() {
  useEffect(() => {
     logEvent(analytics, "BN Contact Click", {
       platform: "Web",
     });
    document.title = "Contact - BN";
  }, [])
  
  return (
    <>
      <div className={style.contactContainer}>
        <header className={style.contactHeader}>
          <a href="/">
            <img style={{ marginTop: "20px" }} src={Logo} alt="Home" />
          </a>
        </header>
        {/* <hr
          style={{
            width: "55%",
            border: "none",
            borderTop: "2px solid lightgray",
          }}
        ></hr> */}
        <main className={style.contactMain}>
          <p className={style.heading}>Contact</p>
          <p className={style.statement}>
            For feedback, help, partnerships, or general enquiries reach us at
          </p>

          <div className={style.contactOption}>
            <a
              href="mailto:hello@getbn.in"
              className={style.contactBox}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              {/* <img
                src={email}
                alt="Email Icon"
                style={{ width: "24px", height: "24px", marginRight: "10px" }}
              /> */}
              <div>
                {/* <p style={{ margin: 0, fontWeight: "bold" }}>
                  Reach us via Email
                </p> */}
                <span style={{ color: "gray" }}>hello@getbn.in</span>
              </div>
            </a>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;

import React, { useEffect } from "react";
import style from "./TermCondition.module.css";
import Logo from "../../Assets/Logo.svg";
import analytics from "../../firebase";
import { logEvent } from "firebase/analytics";

function TermConditions() {
  useEffect(() => {
    logEvent(analytics, "BN Terms Click", {
      platform: "Web",
    });
    document.title = "Terms & Conditions - BN";
  }, []);
  return (
    <>
      <div className={style.termsContainer}>
        <header className={style.termsHeader}>
          <a href="/">
            <img src={Logo} alt="Home" />
          </a>
          <h1 style={{ fontFamily: "plus-jakarta-sans, sans-serif" }}>
            Terms & Conditions
          </h1>
          {/* <h2>Munchmart Technologies Private Limited</h2> */}
        </header>

        <p className={style.termsIntro}>
          Welcome to BN, your go-to app for all things birthday! By accessing or
          using the BN app or website (collectively, the “Platform”), you agree
          to comply with and be bound by these Terms and Conditions (“Terms”).
          If you do not agree to these Terms, please do not use the Platform.
        </p>

        <section className={style.termsSection}>
          <p>
            <strong>1.</strong> This website Acceptance of Terms <br />
            By using the BN app, you acknowledge and agree to these Terms. BN
            reserves the right to update, modify, or change these Terms at any
            time. Any such updates will be posted in the app or on the platform
            and become effective immediately upon posting. You should review
            these Terms regularly. Your continued use of the Platform following
            any changes constitutes your acceptance of the modified Terms.
          </p>
        </section>

        <section className={style.termsSection}>
          <p>
            <strong>2.</strong> Use of the Platform {""}
            <br></br>
            <br></br>
            <strong>2.1</strong> Eligibility <br />
            You must be at least 18 years old to use the Platform. By accessing
            or using the Platform, you confirm that you are at least 18 years of
            age or, if you are under 18 years old, you have obtained the consent
            of your parent or guardian to use the Platform.
          </p>
        </section>

        <section className={style.termsSection}>
          <p>
            <strong>2.2</strong> Account Creation <br /> To access certain
            features of the Platform, you may need to create an account. You
            agree to provide accurate, current, and complete information during
            the registration process and to update such information as necessary
            to maintain its accuracy. You are responsible for maintaining the
            confidentiality of your account credentials, including your username
            and password.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>2.3</strong> Prohibited Use <br />
            You agree not to use the Platform for any unlawful, fraudulent, or
            malicious purpose. Prohibited activities include, but are not
            limited to: - Violating any applicable laws or regulations. -
            Posting or transmitting content that is infringing, defamatory,
            obscene, or harmful. - Attempting to decompile, reverse engineer, or
            disassemble the Platform. - Interfering with or disrupting the
            Platform’s functionality or security.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>3.</strong> Subscription & Payments <br></br>
            <br></br>
            <strong>3.1</strong> Premium Features
            <br /> BN may offer certain premium features or services that
            require a paid subscription. Subscription fees and billing cycles
            will be clearly outlined in the app or on the platform or over
            electronic mail. By subscribing to these services, you agree to pay
            the applicable fees and any additional charges that may apply.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>3.2</strong> Payment <br></br> All payments for premium
            features are processed through a third-party payment processor. By
            providing your payment information, you authorize BN to charge the
            applicable fees for the subscription. You are responsible for
            updating your payment information if it changes.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>4.</strong> Privacy <br></br> Your use of the BN app is also
            governed by our Privacy Policy. By using the app, you consent to the
            collection and use of your information as outlined in the Privacy
            Policy. Please review our Privacy Policy for more details on how we
            collect, use, and protect your personal information.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>5.</strong> Limitation of Liability <br></br> To the fullest
            extent permitted by applicable law, BN shall not be liable for any
            damages, loss of data, or other consequences arising from your use
            of the Platform, including but not limited to errors, omissions, or
            delays in the content.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>6.</strong> Third-Party Links and Content <br></br> The BN
            app or website may contain links to third-party websites or services
            that are not owned or controlled by BN. These links are provided for
            your convenience, and BN is not responsible for the content, privacy
            policies, or practices of any third-party websites or services. You
            access these third-party sites at your own risk.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>7.</strong> Termination <br></br>BN reserves the right to
            suspend or terminate your account at its discretion, especially if
            you violate these Terms. Upon termination, you will lose access to
            the features provided by the app. BN is not liable for any loss of
            data or content upon termination.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>8.</strong> Governing Law and Dispute Resolution Governing
            Law
            <br></br>
            <br></br>
            <strong>8.1</strong> Governing Law <br></br> These Terms are
            governed by and construed in accordance with the laws of India.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>8.2</strong> Dispute Resolution <br></br> Any disputes
            arising from the use of the Platform or purchase of products will be
            subject to the exclusive jurisdiction of the courts in Noida, India.
            If you are from another country, we may bring proceedings against
            you in your country of residence or use. BN accepts no liability for
            non-compliance with the laws of any country other than India.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>9.</strong> Indemnification <br></br> You agree to indemnify
            and hold harmless BN, its affiliates, officers, agents, founder(s)
            and employees from any claims, liabilities, damages, losses, or
            expenses (including legal fees) arising from your use of the
            Platform or your violation of these Terms.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>10.</strong> Changes to Terms <br></br> BN may update or
            modify these Terms at any time. Any changes will be posted within
            the app or on the Platform. By continuing to use the Platform after
            changes are posted, you agree to the updated Terms. You are
            encouraged to review these Terms regularly.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>11.</strong> Contact Information <br></br> For any questions
            regarding these Terms or to report any issues, please contact us at:
            Email: hello@getbn.in
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>12.</strong> Miscellaneous
            <br></br>
            <br></br>
            <strong>12.1</strong> Entire Agreement <br></br>These Terms,
            together with any policies or guidelines incorporated by reference,
            constitute the entire agreement between you and BN regarding your
            use of the Platform.
          </p>
        </section>
        <section className={style.termsSection}>
          <p>
            <strong>12.2</strong> Severability<br></br> If any provision of
            these Terms is deemed invalid or unenforceable, the remaining
            provisions will continue in full force and effect.
          </p>
        </section>
      </div>
    </>
  );
}

export default TermConditions;
